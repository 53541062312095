/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container, Col } from 'react-grid-system';

// UI local components
import AllLinks from './allLinks';

// Style
import '../../PageHeader/PageMenu/index.scss';

/* -------------------------------------------------------------------------- */
/*                          Page Sub Menu Links                               */
/* -------------------------------------------------------------------------- */

function SubMenuLinks({ setMenu }) {
  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="fluid" fluid>
      <Container
        className="sub-menu-container flex justify-evenly flex-wrap fluid"
        fluid
      >
        <Col className="fluid" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
          <AllLinks position="0" size="2" setMenu={setMenu} />
        </Col>
        <Col className="fluid" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
          <AllLinks position="2" size="7" setMenu={setMenu} />
        </Col>
      </Container>
    </Container>
  );
}

SubMenuLinks.propTypes = {
  setMenu: PropTypes.func.isRequired,
};

export default SubMenuLinks;
