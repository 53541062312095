/* eslint-disable operator-linebreak */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';

// PropTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container, Visible, useScreenClass } from 'react-grid-system';
import { Link } from 'gatsby';

// Local Links
import PageMenuLists from '../../local-links/pageMenuLinks/PageMenuLists';

// Assets
import ARROW_ICON from '../../../images/icons/header/arrow.svg';
import ARROW_DOWN_ICON from '../../../images/icons/header/arrow-down.svg';

// Style
import '../../PageHeader/PageMenu/index.scss';

/* -------------------------------------------------------------------------- */
/*                                All Links                                   */
/* -------------------------------------------------------------------------- */

function AllLinks({ position, size, setMenu }) {
  /* ********************************** HOOKS ********************************* */
  const screenClass = useScreenClass();
  const IS_DESKTOP = ['lg', 'xl', 'xxl'].includes(screenClass);

  const [staticLink, setStaticLink] = useState(false);
  const [openSubLinks, setSubLinks] = useState({
    id: '',
    isOpen: false,
  });

  /* ******************************** RENDERING ******************************* */
  return (
    <>
      {position === '0' && size === '2' && (
        <Container className="single-subLink-container fluid">
          <div className="flex items-center justify-between">
            <Link
              to="/understand-horizon/"
              className="single-subLink mb-0"
              target="blank"
              onClick={() => setMenu(false)}
            >
              <h5>Horizon Europe</h5>
            </Link>
            <Visible xs md sm>
              <Container
                className={`arrow-down-icon ${
                  (staticLink || IS_DESKTOP) && 'reverse-arrow'
                } fluid`}
                onClick={() => setStaticLink(!staticLink)}
              >
                <img src={ARROW_DOWN_ICON} alt="arrow-down" />
              </Container>
            </Visible>
          </div>
          {(staticLink || IS_DESKTOP) && (
            <>
              <Container
                className="subLink-container flex fluid"
                fluid
                onClick={() => setMenu(false)}
              >
                <img src={ARROW_ICON} alt="arrow icon" />
                <Link to="/understand-horizon#Présentation-du-programme/">
                  Présentation du programme
                </Link>
              </Container>
              <Container
                className="subLink-container flex fluid"
                fluid
                onClick={() => setMenu(false)}
              >
                <img src={ARROW_ICON} alt="arrow icon" />
                <Link to="/understand-horizon#Ressources-juridiques-et-financières">
                  Ressources juridiques et financières
                </Link>
              </Container>
            </>
          )}
        </Container>
      )}

      {/* MAP MENU */}
      {PageMenuLists.slice(position, size).map((link) => (
        <Container
          key={link.id}
          className="single-subLink-container fluid"
          fluid
        >
          <div className="flex items-center justify-between">
            <Link
              to={link.link}
              className="single-subLink"
              target="blank"
              onClick={() => setMenu(false)}
            >
              <h5>{link.title}</h5>
            </Link>
            <Visible xs md sm>
              <Container
                className={`arrow-down-icon ${
                  openSubLinks.isOpen &&
                  openSubLinks.id === link.id &&
                  'reverse-arrow'
                } fluid`}
                onClick={() => {
                  // setMenu(false);
                  setSubLinks({
                    id: link.id,
                    isOpen: !openSubLinks.isOpen,
                  });
                }}
                fluid
              >
                {link.subLinks && (
                  <img src={ARROW_DOWN_ICON} alt="arrow-down" />
                )}
              </Container>
            </Visible>
          </div>
          {((openSubLinks.isOpen && openSubLinks.id === link.id) ||
            IS_DESKTOP) &&
            link.subLinks?.map((sublink) => (
              <Container
                className="subLink-container flex fluid"
                key={sublink.id}
                fluid
                onClick={() => setMenu(false)}
              >
                <img src={ARROW_ICON} alt="arrow icon" />
                <Link key={sublink.id} to={`/cluster/${sublink.id}/#events`}>
                  {sublink.name}
                </Link>
              </Container>
            ))}
        </Container>
      ))}
    </>
  );
}

AllLinks.propTypes = {
  position: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  setMenu: PropTypes.func.isRequired,
};

export default AllLinks;
