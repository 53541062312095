/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';
import axios from 'axios';

// UI lib component
import { Container } from 'react-grid-system';
// shared components
import PopupForm from '../../UIKit/PopupForm/index';

// APIs
import { LINK_API } from '../../APIs/index';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Footer                                    */
/* -------------------------------------------------------------------------- */

function FooterForm() {
  /* ------------------------------ CONST ---------------------------------- */
  const INITIAL_FORM_DATA = {
    nom: '',
    email: '',
    message: '',
  };

  /* ------------------------------ HOOKS ---------------------------------- */
  const [footerForm, setFooterForm] = useState(INITIAL_FORM_DATA);
  const [showPOPUP, setShowPOPUP] = useState(false);

  /* ------------------------------ HELPERS -------------------------------- */
  const handleSubmit = async (e) => {
    e.preventDefault();
    const FORM_DATA = {
      data: {
        ...footerForm,
      },
    };
    await axios.post(`${LINK_API}/api/newsletters`, FORM_DATA);
    e.target.reset();
    setShowPOPUP(true);
  };

  /* ***************************** RENDERING ******************************* */
  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <Container className="flex fluid" fluid>
          <input
            type="text"
            name="nom"
            placeholder="Nom"
            onChange={(e) =>
              setFooterForm({ ...footerForm, nom: e.target.value })
            }
          />
          <input
            type="text"
            name="email"
            placeholder="Email"
            onChange={(e) =>
              setFooterForm({ ...footerForm, email: e.target.value })
            }
          />
        </Container>
        <textarea
          name="message"
          placeholder="Message"
          onChange={(e) =>
            setFooterForm({ ...footerForm, message: e.target.value })
          }
        />
        <button type="submit">Envoyer</button>
      </form>
      {showPOPUP && (
        <PopupForm
          message="votre message a été envoyé avec succès"
          closePOPUP={setShowPOPUP}
        />
      )}
    </div>
  );
}

export default FooterForm;
