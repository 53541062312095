const FooterLinksApi = [
  {
    id: 1,
    name: 'Le programme',
    URL: '/pillar/science_dexcellence',
  },
  {
    id: 2,
    name: 'Notre réseau',
    URL: '/our-network/',
  },
  {
    id: 3,
    name: "Appels d'affaires",
    URL: '/',
  },
  {
    id: 4,
    name: 'Formation et Coaching',
    URL: '/training-and-coaching/',
  },
  {
    id: 5,
    name: 'Evènement',
    URL: '/evenements/',
  },
];

export default FooterLinksApi;
