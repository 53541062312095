/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// UI lib component
import { Container } from 'react-grid-system';
// import { Link } from 'gatsby';

// Local API
import socialMedia from '../local-links/socialMediaIcons';

// Style
import '../pageFooter/index.scss';

/* -------------------------------------------------------------------------- */
/*                                 Footer Icons                               */
/* -------------------------------------------------------------------------- */

function FooterIcons() {
  /* -------------------------------- CONST --------------------------------- */
  const medias = socialMedia.map((icon) => {
    return (
      <Container key={icon.id} className="icon-wrapper fluid" fluid>
        <a className="single-icon" href={icon.URL} target="blank">
          <img src={icon.icon} alt={icon.title} />
        </a>
      </Container>
    );
  });
  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="fluid" fluid>
      <Container className="icons-container flex fluid" fluid>
        {medias}
      </Container>
    </Container>
  );
}
export default FooterIcons;
