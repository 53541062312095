/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
// UI lib component
import { Container, useScreenClass } from 'react-grid-system';

// UI local components
import PageMenu from './PageMenu/index';
// Assets
import HORIZON_LOGO from '../../images/horizonLogo.png';
import REPUBLIC_LOGO from '../../images/republic.png';
import menu from '../../images/icons/header/menuTwo.png';

// Style
import './index.scss';
import '../styles/global.scss';

/* -------------------------------------------------------------------------- */
/*                                  Header                                    */
/* -------------------------------------------------------------------------- */

function PageHeader() {
  /* ********************************* HOOKS ******************************** */
  const { pathname } = useLocation();
  const [isScrolled, setPageScrolling] = useState(false);
  const [openMenu, setMenu] = useState(false);

  /* ------------------------------- CONSTS --------------------------------- */
  const screenClass = useScreenClass();
  const IS_MOBILE = ['xs', 'sm', 'md'].includes(screenClass);
  const IS_BROWSER = typeof window !== 'undefined';

  /* ------------------------------ HELLPERS -------------------------------- */
  useEffect(() => {
    if (IS_BROWSER) {
      const onScroll = (e) => {
        if (e.target.documentElement.scrollTop > 10) setPageScrolling(true);
        if (e.target.documentElement.scrollTop === 0) setPageScrolling(false);
      };
      window.addEventListener('scroll', onScroll);

      return () => window.removeEventListener('scroll', onScroll);
    }
    return '';
  }, [IS_BROWSER]);

  /* ******************************* RENDERING **************************** */
  return (
    <>
      <PageMenu setMenu={setMenu} openMenu={openMenu} />
      {pathname === '/' ? (
        <header
          className={`page-header flex justify-between items-center ${
            isScrolled && 'unset-padding add-shadow'
          }`}
        >
          <Container className="w-max mobile-fluid">
            <div className="header-container flex justify-between items-center">
              <Link className="republic-logo fluid" to="/">
                <img
                  src={REPUBLIC_LOGO}
                  alt="Logo_Republic"
                  className="republic-log"
                />
                <img
                  className="horizon-logo-two"
                  src={HORIZON_LOGO}
                  alt="Logo Horizon Europe Tunisia"
                />
              </Link>

              <Container
                className={`header-container__icons flex ${
                  IS_MOBILE ? 'items-center' : ''
                } fluid`}
                fluid
              >
                <Container
                  className="menu-icon fluid"
                  onClick={() => setMenu(true)}
                  fluid
                >
                  <img src={menu} alt="icon de menu" />
                </Container>
              </Container>
            </div>
          </Container>
        </header>
      ) : (
        <header
          className={`page-header flex justify-between items-center ${
            isScrolled && 'unset-padding add-shadow'
          }`}
        >
          <Container className="w-max mobile-fluid">
            <div className="header-container flex justify-between items-center">
              <Link className="republic-logo fluid" to="/">
                <img
                  className="horizon-logo-two"
                  src={HORIZON_LOGO}
                  alt="Logo Horizon Europe Tunisia"
                />
              </Link>
              <Container
                className={`header-container__icons flex ${
                  IS_MOBILE ? 'items-center' : ''
                } fluid`}
                fluid
              >
                <Container
                  className="menu-icon fluid"
                  onClick={() => setMenu(true)}
                  fluid
                >
                  <img src={menu} alt="icon de menu" />
                </Container>
              </Container>
            </div>
          </Container>
        </header>
      )}
    </>
  );
}

export default PageHeader;
