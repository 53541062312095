const PageMenuLinks = [
  {
    id: 1,
    title: 'Qui sommes-nous',
    link: '/about-us',
  },
  {
    id: 2,
    title: 'Notre réseau',
    link: '/our-network',
  },
  {
    id: 3,
    title: 'Evénements',
    link: '/evenements',
  },
  {
    id: 4,
    title: 'Formation et coaching',
    link: '/training-and-coaching',
  },
  {
    id: 5,
    title: 'Autres réseaux et programmes',
    link: '/other-networks-and-programs',
  },
  {
    id: 6,
    title: 'Domaines de missions',
    link: '/domaines-de-missions',
    // subLinks: [],
  },
  {
    id: 7,
    title: 'Partenariats',
    link: '/partners',
    // subLinks: [],
  },
];
export default PageMenuLinks;
