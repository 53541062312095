// Assets
import FACEBOOK_ICON from '../../images/footer/facebook.svg';
import TWITTER_ICON from '../../images/footer/twitter.svg';
import YOUTUBE_ICON from '../../images/footer/youtube.svg';
import LINKEDIN_ICON from '../../images/footer/linkedin.svg';

/* --------------------------------- CONST -------------------------------- */
const socialMedia = [
  {
    id: 1,
    icon: FACEBOOK_ICON,
    URL: 'https://www.facebook.com/Horizon-2020-Tunisia-1295419367211846',
    title: 'facebook link',
  },
  {
    id: 2,
    icon: TWITTER_ICON,
    URL: 'https://twitter.com/H2020_Tunisia',
    title: 'twitter link',
  },
  {
    id: 3,
    icon: YOUTUBE_ICON,
    URL: 'https://www.youtube.com/channel/UCYkZao55fN1sC8WrBPnuZ1A',
    title: 'youtube link',
  },
  {
    id: 5,
    icon: LINKEDIN_ICON,
    URL: 'https://www.linkedin.com/company-beta/24783152',
    title: 'linkedin link',
  },
];

export default socialMedia;
