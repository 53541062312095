/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({ description, lang, meta, title, image, siteUrl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            siteUrl
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = title || site.siteMetadata?.title;
  const defaultImage = image || site.siteMetadata?.image;
  const defaultSiteUrl = siteUrl || site.siteMetadata?.siteUrl;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={defaultTitle}
      image={defaultImage}
      siteUrl={defaultSiteUrl}
      titleTemplate={!title ? `%s | ${defaultTitle}` : title}
      meta={[
        {
          charset: 'utf-8',
        },
        {
          name: 'title',
          property: 'og:title',
          content: title,
        },
        {
          name: 'description',
          property: 'og:description',
          content: metaDescription,
        },
        {
          name: 'image',
          property: 'og:image',
          content: image,
        },
        {
          name: 'type',
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata?.author || '',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          property: 'og:url',
          content: siteUrl,
        },
      ].concat(meta)}
    >
      <meta charset="utf-8" />
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: 'fr',
  meta: [],
  description: undefined,
  image: '',
  siteUrl: '',
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  siteUrl: PropTypes.string,
};

export default Seo;
