/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// UI lib component
import { Container } from 'react-grid-system';
import { Link } from 'gatsby';

// Local Links
import FooterLinksApi from '../local-links/footer-links';

// Style
import '../pageFooter/index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Footer Links                              */
/* -------------------------------------------------------------------------- */

function FooterLinks() {
  /* --------------------------------- CONST -------------------------------- */
  const usefullLinks = FooterLinksApi.map((link) => {
    return (
      <Container key={link.id} className="link-wrapper fluid" fluid>
        <Link to={link.URL} className="link" target="blank">
          {link.name}
        </Link>
      </Container>
    );
  });

  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="flex fluid" fluid>
      <Container className="first-column fluid">
        {usefullLinks.slice(0, 3)}
      </Container>
      <Container className="second-column fluid">
        {usefullLinks.slice(-2)}
      </Container>
    </Container>
  );
}
export default FooterLinks;
