/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents
import { Container } from 'react-grid-system';

// Style
import './index.scss';
// assets
import closeIcon from '../../../images/Popup-form/close.png';
import checkIcon from '../../../images/Popup-form/check.png';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function PopupForm({ message, closePOPUP }) {
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="popup-form" fluid>
      <button type="button" onClick={() => closePOPUP(false)}>
        <img src={closeIcon} alt="close-icon" />
      </button>
      <img src={checkIcon} alt="check-icon" />
      <p>{message}</p>
    </Container>
  );
}

PopupForm.propTypes = {
  message: PropTypes.string.isRequired,
  closePOPUP: PropTypes.func.isRequired,
};

export default PopupForm;
