/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container, Hidden, Visible } from 'react-grid-system';

// UI local components
import MenuLinks from '../../constants/Page-Menu/MenuLinks';
import SubMenuLinks from '../../constants/Page-Menu/SubMenuLinks';

// Assets
import CLOSE_ICON from '../../../images/icons/header/close.svg';
import LOGO_MENU from '../../../images/Menu/Horizon.png';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                Page Menu                                   */
/* -------------------------------------------------------------------------- */

function PageMenu({ setMenu, openMenu }) {
  /* ******************************** RENDERING ******************************* */
  return (
    <Container
      className={`page-menu max-w ${openMenu ? 'show-page-menu' : ''} fluid`}
    >
      <Container
        className="close-icon fluid"
        onClick={() => setMenu(!openMenu)}
        fluid
      >
        <img src={CLOSE_ICON} alt="close icon" />
      </Container>
      <Hidden xs sm md>
        <nav className={`${openMenu} ? 'show-nav' : 'hide-nav' nav-desc`}>
          <div className="menu-section">
            <img src={LOGO_MENU} alt="Horizon-Logo" />
            <MenuLinks setMenu={setMenu} />
          </div>
          <div className="submenu-section">
            <SubMenuLinks setMenu={setMenu} />
          </div>
        </nav>
      </Hidden>
      <Visible xs sm md>
        <nav className={openMenu ? 'show-nav' : 'hide-nav'}>
          <MenuLinks setMenu={setMenu} />
          <Container className="top fluid" fluid>
            <Visible xs sm md>
              <h2>Le Programme</h2>
            </Visible>
            <SubMenuLinks setMenu={setMenu} />
          </Container>
        </nav>
      </Visible>
    </Container>
  );
}

PageMenu.propTypes = {
  setMenu: PropTypes.func.isRequired,
  openMenu: PropTypes.bool.isRequired,
};

export default PageMenu;
