const PageMenuLists = [
  {
    id: 1,
    title: "1er pilier : Science d'excellence",
    link: '/cluster/1/#events',
    subLinks: [
      {
        id: 1,
        name: 'Conseil européen de la recherche (ERC)',
        URL: 'cluster/5/#events',
      },
      {
        id: 2,
        name: 'Actions Marie Sklodowska-Curie',
        URL: '/cluster/actions-marie-sklodowska-Curie',
      },
      {
        id: 3,
        name: 'Infrastructures de recherche',
        URL: '/cluster/infrastructures_de_recherche',
      },
    ],
  },
  {
    id: 2,
    title:
      '2ème pilier : Problématiques mondiales et compétitivité industrielle européenne',
    link: '/cluster/4/#events',
    subLinks: [
      { id: 4, name: 'Cluster 1 - Santé', URL: '/cluster/sante' },
      {
        id: 5,
        name: 'Cluster 2 - Culture, créativité et société inclusive',
        URL: '/cluster/culture-creativite-societe-inclusive',
      },
      {
        id: 6,
        name: 'Cluster 3 - Sécurité civile pour la société',
        URL: '/cluster/securite-civile',
      },
      {
        id: 7,
        name: 'Cluster 4 - Numérique, industrie et espace',
        URL: '/cluster/numerique-industrie-espace',
      },
      {
        id: 8,
        name: 'Cluster 5 - Climat, énergie et mobilité',
        URL: '/cluster/climat-energie',
      },
      {
        id: 9,
        name: 'Cluster 6 - Alimentation, bioéconomie, ressources naturelles, agriculture et environnement',
        URL: '/cluster/alimentation',
      },
    ],
  },
  {
    id: 3,
    title: '3ème pilier : Europe plus innovante',
    link: '/cluster/10/#events',
    subLinks: [
      {
        id: 10,
        name: "Conseil européen de l'innovation (EIC)",
        URL: '/cluster/conseil-europeen',
      },
      {
        id: 11,
        name: "L'institut européen d'innovation et de technologie",
        URL: '/cluster/institut-europeen',
      },
    ],
  },
  {
    id: 4,
    title: 'Pilier transversal ',
    link: '/cluster/12/#events',
    subLinks: [
      {
        id: 12,
        name: "Élargir la participation et renforcer l'espace européen de la recherche",
        URL: '/cluster/elargir-participation',
      },
      { id: 13, name: 'Programme COST', URL: '/cluster/cost' },
    ],
  },
];
export default PageMenuLists;

export const subLinks = [
  {
    id: 1,
    name: 'Conseil européen de la recherche (ERC)',
    URL: '/cluster/conseil_europeen',
  },
  {
    id: 2,
    name: 'Actions Marie Sklodowska-Curie',
    URL: '/cluster/actions-marie-sklodowska-Curie',
  },
  {
    id: 3,
    name: 'Infrastructures de recherche',
    URL: '/cluster/infrastructures_de_recherche',
  },
  { id: 4, name: 'Cluster 1 - Santé', URL: '/cluster/sante' },
  {
    id: 5,
    name: 'Cluster 2 - Culture, créativité et société inclusive',
    URL: '/cluster/culture-creativite-societe-inclusive',
  },
  {
    id: 6,
    name: 'Cluster 3 - Sécurité civile pour la société',
    URL: '/cluster/securite-civile',
  },
  {
    id: 7,
    name: 'Cluster 4 - Numérique, industrie et espace',
    URL: '/cluster/numerique-industrie-espace',
  },
  {
    id: 8,
    name: 'Cluster 5 - Climat, énergie et mobilité',
    URL: '/cluster/climat-energie',
  },
  {
    id: 9,
    name: 'Cluster 6 - Alimentation, bioéconomie, ressources naturelles, agriculture et environnement',
    URL: '/cluster/alimentation',
  },
  {
    id: 10,
    name: "1er pilier : Science d'excellence",
    URL: '/pillar/science_dexcellence',
  },
  {
    id: 11,
    name: '2ème pilier : Problématiques mondiales et compétitivité industrielle européenne',
    URL: '/pillar/problematiques_mondiales',
  },
  {
    id: 12,
    name: '3ème pilier : Europe plus innovante',
    URL: '/pillar/europe_plus_innovante',
  },
];
