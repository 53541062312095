/* eslint-disable jsx-a11y/label-has-associated-control */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { navigate } from 'gatsby';

// UI lib component
import { Container, Row, Col } from 'react-grid-system';

// UI local components
import FooterForm from './footer-form';
import FooterLinks from '../constants/footerLinks';
import FooterIcons from '../constants/socialMedia';

// Style
import './index.scss';
import '../styles/global.scss';
// Assets
import FLAG from '../../images/footer/Flag.png';
/* -------------------------------------------------------------------------- */
/*                                  Footer                                 */
/* -------------------------------------------------------------------------- */

function PageFooter() {
  return (
    <>
      <Container className="page-footer" fluid>
        <Row className="page-footer__content fluid">
          <Col
            className="title-container fluid"
            xxl={7}
            xl={7}
            lg={7}
            md={6}
            sm={12}
            xs={12}
          >
            <button
              type="button"
              onClick={() => navigate('https://ugpo.horizon-europe.tn')}
            >
              <h2 className="title">S&apos;inscrire à la communauté UGPO</h2>
            </button>
            <FooterForm />
          </Col>

          <Col
            className="footer-links fluid"
            xxl={5}
            xl={5}
            lg={5}
            md={6}
            sm={12}
            xs={12}
          >
            <FooterLinks />
          </Col>
        </Row>
      </Container>
      <footer>
        <Container className="fluid" fluid>
          <Row className=" fluid">
            <Col
              className="copyright flex items-center fluid"
              xxl={7}
              xl={7}
              lg={7}
              md={6}
              sm={12}
              xs={12}
            >
              <p>Copyright &copy; 2021 Horizon Europe Tunisia</p>
            </Col>

            <Col
              className="social-media flex items-center fluid"
              xxl={5}
              xl={5}
              lg={5}
              md={6}
              sm={12}
              xs={12}
            >
              <FooterIcons />
            </Col>
          </Row>
        </Container>
      </footer>
      <div className="developer-by">
        <Row align="center" justify="center" style={{ margin: '2rem 0' }}>
          <div className="europe-union">
            <img
              src={FLAG}
              alt="flag-europe-union"
              className="flag-europe-union"
            />
            <p className="developed-by">
              Ce site a été développé avec le soutien de l’Union Européenne à
              travers le programme EMORI
            </p>
          </div>
        </Row>
      </div>
    </>
  );
}

export default PageFooter;
