/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container } from 'react-grid-system';
import { Link } from 'gatsby';

// Local Links
import PageMenuLinks from '../../local-links/pageMenuLinks/pageMenuLinks';

// Style
import '../../PageHeader/PageMenu/index.scss';

/* -------------------------------------------------------------------------- */
/*                              Page Menu Links                               */
/* -------------------------------------------------------------------------- */

function MenuLinks({ setMenu }) {
  /* --------------------------------- CONST -------------------------------- */
  const menuLinks = PageMenuLinks.map((link) => {
    return (
      <Container
        key={link.id}
        className="fluid"
        fluid
        onClick={() => setMenu(false)}
      >
        <Link to={link.link} target="blank">
          <h5>{link.title}</h5>
        </Link>
      </Container>
    );
  });
  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="fluid" style={{ maxWidth: '100%' }}>
      <div
        className="menu-container flex justify-between flex-wrap fluid"
        fluid
      >
        {menuLinks}
      </div>
    </Container>
  );
}

MenuLinks.propTypes = {
  setMenu: PropTypes.func.isRequired,
};

export default MenuLinks;
