/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
const API = (collection) =>
  `https://admin522.horizon-europe.tn/api/${collection}?sort[0]=publishedAt%3Adesc&[populate]=*`;
export default API;

// export const API_CLUSTERS =
// 'https://admin522.horizon-europe.tn/api/page-clusters?populate[about][populate]=*&populate[cluster][populate]=*&populate[pcns][populate]=*&populate[page_pilier][populate]=*&populate[useful_links][populate]=*';

export const API_CLUSTERS = (PAGE_ID) =>
  `https://admin522.horizon-europe.tn/api/page-clusters/${PAGE_ID}?populate[about][populate]=*&populate[cluster][populate]=*&populate[pcns][populate]=*&populate[page_pilier][populate]=*&populate[useful_links][populate]=*`;

export const LINK_API = 'https://admin522.horizon-europe.tn';

export const API_JALIOS_ACTUALITIES =
  'https://ugpo.horizon-europe.tn/rest/search?types=Article&authKey=djI7cDJfMzE1MDI7MTc3MzM5MzM5NjQ3OTtHRVQsOzszMTskMmEkMDQkdENLVnpoV0o3TXJ2aVlvTHVaN1ZhT0Q1YlA4dmJrOGRxMWxLY3MuNmc0cVhsWElabGtPVks=?title';

export const API_JALIOS_TRAININGS =
  'https://ugpo.horizon-europe.tn/rest/search/UGPOFormations?authKey=djI7cDJfMzE1MDI7MTc3MzM5MzM5NjQ3OTtHRVQsOzszMTskMmEkMDQkdENLVnpoV0o3TXJ2aVlvTHVaN1ZhT0Q1YlA4dmJrOGRxMWxLY3MuNmc0cVhsWElabGtPVks';

export const API_JALIOS_WEBINAIRE_EVENT =
  'https://ugpo.horizon-europe.tn/rest/search/EventsByCategoryType/c_18947/2?authKey=djI7cDJfMzE1MDI7MTc3MzM5MzM5NjQ3OTtHRVQsOzszMTskMmEkMDQkdENLVnpoV0o3TXJ2aVlvTHVaN1ZhT0Q1YlA4dmJrOGRxMWxLY3MuNmc0cVhsWElabGtPVks';
export const API_JALIOS_INFODAY_EVENT =
  'https://ugpo.horizon-europe.tn/rest/search/EventsByCategoryType/c_18947/1?authKey=djI7cDJfMzE1MDI7MTc3MzM5MzM5NjQ3OTtHRVQsOzszMTskMmEkMDQkdENLVnpoV0o3TXJ2aVlvTHVaN1ZhT0Q1YlA4dmJrOGRxMWxLY3MuNmc0cVhsWElabGtPVks';
export const API_JALIOS_COACHING =
  'https://ugpo.horizon-europe.tn/rest/search?types=Coaching&authKey=djI7cDJfMzE1MDI7MTc3MzM5MzM5NjQ3OTtHRVQsOzszMTskMmEkMDQkdENLVnpoV0o3TXJ2aVlvTHVaN1ZhT0Q1YlA4dmJrOGRxMWxLY3MuNmc0cVhsWElabGtPVks';

export const API_JALIOS_ALL_EVENTS =
  'https://ugpo.horizon-europe.tn/rest/search/EventsByCategoryType/c_18947/1?authKey=djI7cDJfMzE1MDI7MTc3MzM5MzM5NjQ3OTtHRVQsOzszMTskMmEkMDQkdENLVnpoV0o3TXJ2aVlvTHVaN1ZhT0Q1YlA4dmJrOGRxMWxLY3MuNmc0cVhsWElabGtPVks';
export const JALIOS_EVENTS =
  'https://ugpo.horizon-europe.tn/rest/search/EventsByCategoryType/c_18947?authKey=djI7cDJfMzE1MDI7MTc3MzM5MzM5NjQ3OTtHRVQsOzszMTskMmEkMDQkdENLVnpoV0o3TXJ2aVlvTHVaN1ZhT0Q1YlA4dmJrOGRxMWxLY3MuNmc0cVhsWElabGtPVks';

export const JALIOS_EVENTS_BY_TYPE = (EVENT_TYPE_ID) =>
  ` https://ugpo.horizon-europe.tn/rest/search/EventsByCategoryType/c_18947/${EVENT_TYPE_ID}?authKey=djI7cDJfMzE1MDI7MTc3MzM5MzM5NjQ3OTtHRVQsOzszMTskMmEkMDQkdENLVnpoV0o3TXJ2aVlvTHVaN1ZhT0Q1YlA4dmJrOGRxMWxLY3MuNmc0cVhsWElabGtPVks`;
export const POPUP_LINK =
  'https://ugpo.horizon-europe.tn/jcms/af_20230/en/ugpo-accueil';

export const JALIOS_MEDIA_UPLOADS = (Media) =>
  `https://ugpo.horizon-europe.tn/${Media}`;
export const EVENTS_HOMEPAGE = () =>
  'https://admin522.horizon-europe.tn/api/events?sort[0]=date%3Adesc&[populate]=*';

export const EVENT_JALIOS_IMAGE_SOURCE = (filepath) =>
  `https://ugpo.horizon-europe.tn/rest/data/${filepath}?authKey=djI7cDJfMzE1MDI7MTc3MzM5MzM5NjQ3OTtHRVQsOzszMTskMmEkMDQkdENLVnpoV0o3TXJ2aVlvTHVaN1ZhT0Q1YlA4dmJrOGRxMWxLY3MuNmc0cVhsWElabGtPVks`;
