/* eslint-disable operator-linebreak */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

// UI lib component
import { Container } from 'react-grid-system';

// Local UI components
import PageHeader from '../PageHeader';

// Style
import './index.scss';
import PageFooter from '../pageFooter';

/* -------------------------------------------------------------------------- */
/*                                  Page Layout                               */
/* -------------------------------------------------------------------------- */
function PageLayout({ children, loader, location }) {
  /* ********************************** HOOKS ********************************* */
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    setShowFooter(true);
    if (
      location?.pathname.split('/')[1] === 'pillar' ||
      location?.pathname.split('/')[1] === 'cluster' ||
      location?.pathname.split('/')[1] === 'contacts'
    ) {
      setShowFooter(false);
      setTimeout(() => {
        setShowFooter(true);
      }, 500);
    }
  }, [loader, location?.pathname]);

  function getFooter() {
    if (showFooter) {
      return <PageFooter />;
    }

    return '';
  }

  function getContent() {
    if (loader) {
      return <Container className="loader fluid" />;
    }

    return (
      <>
        <main className="page-layout">{children}</main>
        {getFooter()}
      </>
    );
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="page-layout fluid" fluid>
      <PageHeader siteTitle={data.site.siteMetadata?.title || 'Title'} />
      {getContent(data)}
    </Container>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  loader: PropTypes.bool,
  location: PropTypes.objectOf(PropTypes.object),
};

PageLayout.defaultProps = {
  location: undefined,
  loader: undefined,
};

export default PageLayout;
